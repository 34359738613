import React from "react";
import {createStyles, withStyles} from "@material-ui/core";
import {withRouter} from "next/router";
import SiteGroups from "../components/SiteGroups";
import GenericGroups from "../components/GenericGroups";
import getGlobalProps from "../utils/getGlobalProps"
import {WithRouterProps} from "next/dist/client/with-router";
import Layout from "../components/Layout"
import I18nMessage from "../components/i18n/I18nMessage";
import PortalLink from "../components/PortalLink";
import {checkAuth} from "../utils/auth";
import {assignSsoInfoProp} from "../utils/PropUtil";
import {getClientSideConfig, handleForceStatusCode, handlePageError} from "../utils/PageUtil";
import ErrorPage from "./error";
import HerettoDeployWindowObj, {ClientEvent} from "../components/HerettoDeployWindowObj";
import ContentMeta from "../components/ContentMeta";

const indexStyles = createStyles((theme: any) => ({
    index: theme.index || {},
    indexBanner: theme.indexBanner || {},
    indexSiteSectionTitle: theme.indexSiteSectionTitle,
    indexSiteSectionTitleWrapper: theme.indexSiteSectionTitleWrapper,
    indexSiteSectionDescription: theme.indexSiteSectionDescription,
    indexSiteSectionLink: theme.indexSiteSectionLink,
    indexSiteSectionContainer: theme.indexSiteSectionContainer,
    indexSiteSectionHeader: theme.indexSiteSectionHeader,
    indexSiteSectionIcon: theme.indexSiteSectionIcon
}));

interface IIndexProps {
    runtimeConfig: any;
    layoutProps: any;
    siteSections: any[];
    externalSites: any[];
    relatedResources: any[];
    featured: [];
    whatsNew: [];
    videos: [];
    banners: any[];
    classes?: any;
    groups?: any;
    showErrorPage: boolean;
}

class Index extends React.Component<IIndexProps & WithRouterProps> {
    state = {
        searchText: "",
        isClientSide: false,
    };

    static defaultProps = {
        banners: [],
        siteSections: [],
        externalSites: []
    }

    constructor(props) {
        super(props);
        this.state = {
            searchText: "",
            isClientSide: false,
        };
    }

    componentDidMount() {
        const combinedSiteSections = [...this.props.siteSections, ...this.props.externalSites] as any;
        if (combinedSiteSections.length === 1) {
            this.props.router?.replace(combinedSiteSections[0].href)
        }
        setTimeout(() => HerettoDeployWindowObj.triggerEvent(ClientEvent.pageUpdate))
    }

    componentDidUpdate() {
        setTimeout(() => HerettoDeployWindowObj.triggerEvent(ClientEvent.pageUpdate))
    }

    render() {
        if (this.props.showErrorPage) {
            return <ErrorPage {...this.props}/>
        }
        const {runtimeConfig} = this.props;
        
        const {
            videos,
            featured,
            whatsNew,
            relatedResources,
            externalSites = [],
            externalProducts,
            footer,
            header,
            sidebar,
            ...other
        } = this.props.groups;
        const combinedSections = [...this.props.siteSections, ...externalSites];
        return (
            <Layout {...this.props.layoutProps}
                    relatedResources={relatedResources}
                    className={`index-root`}>
                <ContentMeta meta={this.props.runtimeConfig?.meta}
                             seo={this.props.runtimeConfig?.seo || {}}
                             keywords={this.props.runtimeConfig?.keywords}
                             title={this.props.runtimeConfig?.title}
                />
                <SiteGroups
                    className={this.props.classes.index}
                    videos={videos}
                    featured={featured}
                    whatsNew={whatsNew}
                    relatedResources={relatedResources}
                >
                    <I18nMessage className={this.props.classes.indexSiteSectionHeader}
                                 variant="h3"
                                 id={"message.explore"}/>
                    <div className={this.props.classes.indexSiteSectionContainer}>
                        {combinedSections.map((obj: any, index) => {
                                const thumbnail = obj?.thumbnail || runtimeConfig.defaultSectionIcon;
                                let {href} = obj || {};
                                if (obj?.versions && obj?.versions.length) {
                                    href = obj.versions[0].href;
                                }
                                const shortDescription = obj?.shortDescription || obj?.shortdesc;
                                return (
                                    <PortalLink
                                        className={this.props.classes.indexSiteSectionLink + ` ${obj.outputclasses?.join(" ")}`}
                                        href={href}
                                        key={index}>
                                        {thumbnail ? <img
                                            className={`no-alt ${this.props.classes.indexSiteSectionIcon}`}
                                            alt={"thumbnail"}
                                            src={thumbnail}/> : null}
                                        <div
                                            className={this.props.classes.indexSiteSectionTitleWrapper}>
                                            <I18nMessage id={obj?.label || obj?.title}
                                                         className={this.props.classes.indexSiteSectionTitle}/>
                                            {shortDescription && <I18nMessage id={shortDescription}
                                                                              className={this.props.classes.indexSiteSectionDescription}/>}
                                        </div>
                                    </PortalLink>)
                            }
                        )}
                    </div>
                </SiteGroups>
                <GenericGroups
                    className={this.props.classes.index}
                    groups={other}
                >
                </GenericGroups>
            </Layout>
        );
    }
}

export async function getServerSideProps({req, res, locale}) {
    try {
        const props = await getGlobalProps(req.client, req.runtimeConfig, null, req.query, {
            ["Accept-Language"]: locale
        });
        props.structure = null;
        props.sectionVersions = null;
        assignSsoInfoProp(props, req)
        handleForceStatusCode(res)
        props.runtimeConfig = getClientSideConfig(props.runtimeConfig)
        props.runtimeConfig.seo = req.runtimeConfig.seo 
        return checkAuth(req) || JSON.parse(JSON.stringify({props}));
    } catch (ex) {
        return handlePageError(ex, locale, req, res)
    }
}

//@ts-ignore
export default withRouter(withStyles(indexStyles, {name: "Index"})(Index)) as any;
